<template>
    <!-- This example requires Tailwind CSS v2.0+ -->
    <transition name="fade">
        <div v-if="input" class="fixed inset-0 overflow-hidden z-50" aria-labelledby="slide-over-title" role="dialog" aria-modal="true">
            <div class="absolute inset-0 overflow-hidden">
                <transition
                    enter-active-class="ease-in-out duration-500"
                    leave-active-class="opacity-0"
                    enter-class="opacity-100"
                    enter-to-class="ease-in-out duration-500"
                    leave-class="opacity-100"
                    leave-to-class="opacity-0"
                >
                    <!--
                        Background overlay, show/hide based on slide-over state.

                        Entering: "ease-in-out duration-500"
                            From: "opacity-0"
                            To: "opacity-100"
                        Leaving: "ease-in-out duration-500"
                            From: "opacity-100"
                            To: "opacity-0"
                    -->
                    <div v-if="input" class="absolute inset-0 bg-black bg-opacity-95 transition-opacity" aria-hidden="true" @click.stop="toggleInput" />
                </transition>

                <div class="fixed inset-y-0 right-0 sm:pl-10 max-w-full flex">
                    <!--
                        Slide-over panel, show/hide based on slide-over state.

                        Entering: "transform transition ease-in-out duration-500 sm:duration-700"
                        From: "translate-x-full"
                        To: "translate-x-0"
                        Leaving: "transform transition ease-in-out duration-500 sm:duration-700"
                        From: "translate-x-0"
                        To: "translate-x-full"
                    -->
                    <transition
                        enter-active-class="transform transition ease-in-out duration-500 sm:duration-700"
                        leave-active-class="translate-x-full"
                        enter-class="translate-x-0"
                        enter-to-class="transform transition ease-in-out duration-500 sm:duration-700"
                        leave-class="translate-x-0"
                        leave-to-class="translate-x-full"
                    >
                        <div v-if="input" class="w-screen max-w-4xl">
                            <v-card class="h-full flex flex-col overflow-y-scroll">
                                <div>
                                    <div class="py-default">
                                        <div class="px-default flex items-center justify-between">
                                            <slot name="header">
                                                <h2 id="slide-over-title" class="text-lg font-medium text-gray-300">
                                                    {{ title }}
                                                </h2>
                                            </slot>

                                            <div class="ml-3 h-7 flex items-center">
                                                <v-button color="primary" @click.stop="toggleInput">
                                                    <i class="fa fa-times text-base" />
                                                </v-button>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="relative flex-1">
                                        <!-- Replace with your content -->
                                        <slot />
                                        <!-- /End replace -->
                                    </div>
                                </div>
                            </v-card>
                        </div>
                    </transition>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: "SlideOver",
    props: {
        value: {
            type: Boolean,
            required: false,
            default: () => true,
        },
        title: {
            type: String,
            required: false,
            default: () => "Slide-Over Title",
        },
    },
    data() {
        return {
            input: false,
        };
    },
    watch: {
        value: {
            handler(value) {
                this.input = value;
            },
            immediate: true,
        },
        input: {
            handler(value) {
                this.$emit("input", value);
            },
        },
    },
    created() {
        document.addEventListener("keydown", (e) => {
            if (e.key === "Escape") {
                this.input = false;
            }
        });
    },
    methods: {
        toggleInput() {
            this.input = !this.input;
        },
    },
};
</script>

<style lang="scss">
    .slide-over-layout {
        background-image: linear-gradient(to top right, rgba(0,0,0,.3), rgba(0,0,0,.3)), url('./../assets/slideover-bg.jpg');
        background-repeat: no-repeat;
        background-size: cover;
    }
</style>
